<template lang="pug">
modal(size="sm" @close='$emit("close")')
  generic-form(
    ref='form'
    :endpoint='`/_/ResetOfferForm`'
    :allowShortcuts='true'
    @submitSuccess='$emit("close")'
  )
    template(#default)
      form-header
        | {{$t("Reset Offer")}}
      form-hidden(name='Id' :modelValue='id')
      form-textarea(name="ResetReason")
      form-footer
        form-action(type="secondary" :primary='true' name="SendReset")

</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormResetOffer = defineComponent({
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
  },
})
export default FormResetOffer

</script>
